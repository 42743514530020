import type { NextPage } from 'next';
import Layout from '@components/global/main/layout';
import HeaderBackground from '@components/global/main/background';
import Service from '@components/global/routers/service';
import Footer from '@components/global/main/footer';
import Navbar from '@components/global/main/navbar';
import Company from '@data/company.json';
import { useGetQrPage } from '@services/auth';
import { useEffect, useState } from 'react';
import Loader from '@components/global/loader';
import isEmpty from '@utils/isEmpty';
import moment from 'moment';

const Home: NextPage = () => {
  const [nowYear, setNowYear] = useState(19);
  const { data: qrData, loading: isLoading } = useGetQrPage({
    subUrl: '/rest/provider/v1/qr/',
  });
  const [newsData, setNewsData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [statisticData, setStatisticData] = useState([]);
  useEffect(() => {
    setNowYear(moment().diff(moment('2004', 'YYYY'), 'years'));
    setNewsData(qrData && qrData?.result?.['News']);
    setReviewData(qrData && qrData?.result?.['Comment']);
    setSchoolData(qrData && qrData?.result?.['University']);
    setStatisticData(qrData && qrData?.result?.['statistic']);
  }, [qrData]);

  return (
    <Layout>
      {/*  */}
      <Navbar />
      {isLoading && <Loader />}
      {!isEmpty(statisticData) && (
        <HeaderBackground year={nowYear} statisticData={statisticData} />
      )}
      <Service
        newsData={newsData}
        reviewData={reviewData}
        schoolData={schoolData}
      />
      <Footer companyInfo={Company} year={nowYear} />
    </Layout>
  );
};

export default Home;
