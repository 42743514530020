export const displayHotelStar = star => {
  switch (star) {
    case 1:
      return (
        <li>
          <img src="/assets/icons/solid-star.svg" alt="" width={20} />
        </li>
      );
    case 2:
      return (
        <>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
        </>
      );
    case 3:
      return (
        <>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
        </>
      );
    case 4:
      return (
        <>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
        </>
      );
    case 5:
      return (
        <>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
          <li>
            <img src="/assets/icons/solid-star.svg" alt="" width={20} />
          </li>
        </>
      );
    default:
      return (
        <li>
          <img src="/assets/icons/solid-star.svg" alt="" width={20} />
        </li>
      );
  }
};
