import Numeral from 'numeral';
import { useEffect, useState } from 'react';

const Price = ({ price }) => {
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }

  if (typeof window === 'undefined') {
    return <></>;
  } else {
    return <>{Numeral(Math.ceil(price)).format('0,0')}</>;
  }
};
export default Price;
