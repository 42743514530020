import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import ContentWrapper from '@utils/style';
SwiperCore.use([Navigation, Pagination, Autoplay]);

const Service = ({ data }) => {
  const router = useRouter();

  return (
    <div className="bg-mainc py-10">
      <div className="max-w-7xl mx-auto px-6" data-aos="zoom-in">
        <div className="flex justify-between items-start my-12">
          <h1 className="font-bold uppercase text-2xl text-white md:text-3xl">
            <b className="text-button">Эдүлайн</b> мэдээнүүд
          </h1>
          <a
            href="/news"
            className="bg-yellowc hover:scale-105 cursor-pointer text-white font-bold p-2 px-3 rounded text-[14px]"
          >
            Бүгд
          </a>
        </div>
        <ContentWrapper>
          <Swiper
            slidesPerView={1.1}
            spaceBetween={10}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              940: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              600: {
                slidesPerView: 1.4,
                spaceBetween: 10,
              },
            }}
          >
            {data &&
              data.map((news, i) => (
                <SwiperSlide key={i}>
                  <div onClick={() => router.push(`/news/${news?.path}`)}>
                    <div className="grid grid-cols-2 items-center cursor-pointer hover:shadow gap-4 scale-100 hover:scale-105 ease-in duration-500 rounded-lg bg-white">
                      <img
                        src={news?.description_pic}
                        alt={news?.title}
                        className="max-h-60 rounded-l-lg w-full object-cover"
                      />
                      <div className="p-4">
                        <h1 className="text-xs">
                          {news?.updated_at?.slice(0, 10)}
                        </h1>
                        <h1 className="font-semibold text-base">
                          {news?.title}
                        </h1>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </ContentWrapper>
      </div>
    </div>
  );
};
export default Service;
