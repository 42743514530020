import { displayHotelStar } from '../../ui/getStar';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import ContentWrapper from '@utils/style';
SwiperCore.use([Navigation, Pagination, Autoplay]);

const Service = ({ data }) => {
  function truncate(str, n) {
    return str && str?.length > n ? str.slice(0, n - 1) + '...' : str;
  }
  return (
    <div className="bg-[#f5f3fa] py-10">
      <div className="max-w-7xl mx-auto px-6" data-aos="fade-up">
        <h1 className="font-bold uppercase text-2xl text-black md:text-3xl mb-8">
          <b className="text-mainc">Эдүлайн</b> сэтгэгдлүүд
        </h1>
        <ContentWrapper>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={10}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              940: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              600: {
                slidesPerView: 2.4,
                spaceBetween: 10,
              },
            }}
          >
            {data &&
              data?.map((comment, i) => (
                <SwiperSlide key={i}>
                  <div className="cursor-pointer rounded-lg bg-white hover:shadow p-4">
                    <div className="flex items-center gap-2 mb-4">
                      <img
                        src={comment?.pro_image}
                        alt=""
                        className="object-cover h-12 w-12 rounded-full"
                      />
                      <div className="">
                        <h1 className="text-xs font-semibold">
                          {comment?.updated_at?.slice(0, 10)}
                        </h1>
                        <ul className="flex items-center gap-1">
                          {displayHotelStar(parseInt(comment?.count))}
                        </ul>
                      </div>
                    </div>

                    <div>
                      <h1 className="font-bold">{comment?.name}</h1>
                      <p className="text-sm opacity-80">
                        {truncate(comment?.description, 200)}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </ContentWrapper>
      </div>
    </div>
  );
};
export default Service;
