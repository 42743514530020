import styled from 'styled-components';

const ContentWrapper = styled.div`
  .swiper-wrapper {
    margin-bottom: 60px;
  }
  .swiper-pagination {
    width: 100%;
  }
`;

export default ContentWrapper;
