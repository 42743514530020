import Price from '@components/ui/price';
import {
  AcademicCapIcon,
  PencilAltIcon,
  UserAddIcon,
  UserGroupIcon,
} from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import isEmpty from '@utils/isEmpty';
import moment from 'moment';
import { displayHotelStar } from '@components/ui/getStar';
import RegisterModal from '@components/register/registerModal';

export default function HeaderBackground({ year, statisticData }) {
  const [userTotal, setUserTotal] = useState(
    statisticData['student_count'] && statisticData['student_count'] - 250
  );
  const [moneyTotal, setMoneyTotal] = useState(
    statisticData['scholarship_amount'] &&
      statisticData['scholarship_amount'] - 250
  );
  const [isRegister, setIsRegister] = useState(false);
  function handlePlusNumber() {
    setInterval(
      () =>
        setUserTotal(oldCount =>
          oldCount < statisticData['student_count'] &&
          statisticData['student_count']
            ? oldCount + 1
            : oldCount
        ),
      1
    );
    setInterval(
      () =>
        setMoneyTotal(oldCount =>
          oldCount < statisticData['scholarship_amount'] &&
          statisticData['scholarship_amount']
            ? oldCount + 1
            : oldCount
        ),
      1
    );
  }
  useEffect(() => {
    if (!isEmpty(statisticData)) {
      handlePlusNumber();
    }
    Aos.init({ duration: 1000 });
  }, [statisticData]);

  return (
    <div className="relative lg:h-screen">
      <div className="relative z-10 px-4 py-20 lg:py-0 md:px-8">
        <div className="max-w-2xl lg:max-w-7xl mx-auto flex justify-center">
          <div className="grid lg:grid-cols-2 lg:h-screen items-center gap-10 xl:gap-0">
            <div className="text-white">
              <li className="text-center lg:text-left text-sm pt-6 lg:pt-0">
                {'' +
                  year +
                  ' ЖИЛИЙН ТУРШЛАГАТАЙ БОЛОВСРОЛ ЗУУЧЛАЛЫН АНХДАГЧ БАЙГУУЛЛАГА '}
              </li>
              <div className="text-center lg:text-left text-3xl lg:text-5xl bold font-semibold mb-10 pt-2">
                ЭДҮЛАЙН ХХК
              </div>
              <div data-aos="fade-right" className="max-w-3xl">
                <div className="text-center lg:text-left text-2xl lg:text-3xl font-semibold mb-6 bg-gradient-to-r from-white/90 to-white/90 bg-clip-text text-transparent">
                  Зөв сонголт, Ирээдүйн баталгаа
                </div>
                <h1 className="text-xl font-semibold text-yellow-400 my-2">
                  Эрхэм зорилго
                </h1>
                <p className="text-base text-justify pb-8">
                  Бидний эрхэм зорилт бол нийгэмд хэрэгцээтэй байгаа боловсролын
                  чиг хандлагыг тодорхойлж, оюутан сурагчдыг мэргэжлийн
                  сонголтоо зөв хийж, чанартай олон улсад хүлээн зөвшөөрөгдсөн
                  боловсрол эзэмшүүлэх, дэлхийд үнэлэгдэхүйц боловсон хүчин
                  бэлтгэн гаргахад хувь нэмрээ оруулах явдал билээ.
                </p>
                <h1 className="text-xl font-semibold text-yellow-400 my-2">
                  Үйл ажиллагааны чиглэл
                </h1>
                <div className="space-y-1 text-base pb-10">
                  <ul className="flex items-center gap-2">
                    {displayHotelStar(1)} БНСУ-д ХЭЛНИЙ БЭЛТГЭЛ
                  </ul>
                  <ul className="flex items-center gap-2">
                    {displayHotelStar(1)} БНСУ-д БАКАЛАВР, МАГИСТР, ДОКТОР
                  </ul>
                  <ul className="flex items-center gap-2">
                    {displayHotelStar(1)} БНСУ-ын ЗАСГИЙН ГАЗРЫН ТЭТГЭЛЭГТ
                    ХӨТӨЛБӨР
                  </ul>
                  <ul className="flex items-center gap-2">
                    {displayHotelStar(1)} МОНГОЛ ДАХЬ СОЛОНГОС ХЭЛНИЙ АКАДЕМИ
                  </ul>
                  <ul className="flex items-center gap-2">
                    {displayHotelStar(1)} ФИЛИППИН ДЭХ АНГЛИ ХЭЛНИЙ СУРГАЛТ
                  </ul>
                </div>
              </div>
              <div
                onClick={() => setIsRegister(true)}
                className="md:col-span-2 flex justify-center lg:justify-start"
              >
                <button className="bg-[#ec268f] md:font-semibold px-4 md:px-6 py-3 md:py-4 md:text-lg rounded-lg flex items-center gap-1 hover:scale-105">
                  <PencilAltIcon className="h-5 w-5 md:h-6 md:w-6 " />
                  Бүртгүүлэх
                </button>
              </div>
            </div>
            <div className="flex justify-center lg:justify-end text-white">
              <div className="w-full lg:max-w-lg backdrop-blur-sm bg-white/40 lg:bg-mainc/40 rounded-xl px-8 py-20 space-y-8">
                <div>
                  <div className="flex justify-center mb-2">
                    <div className="flex justify-center items-center rounded-full p-2 bg-mainc w-14 h-14  font-black text-lg">
                      <UserGroupIcon className="md:w-14 md:w-14 w-10 h-10" />
                    </div>
                  </div>
                  <h1 className="mt-2 text-xl md:text-4xl text-center font-bold">
                    <Price price={userTotal} /> ш
                  </h1>
                  <p className="text-base md:text-lg text-center">
                    Эдүлайнаар зуучлуулсан нийт оюутны тоо
                  </p>
                </div>
                <div>
                  <div className="flex justify-center mb-2">
                    <div className="flex justify-center items-center rounded-full p-2 bg-pinkc w-14 h-14  font-bold text-4xl">
                      <AcademicCapIcon className="md:w-14 md:w-14 w-10 h-10" />
                    </div>
                  </div>

                  <h1 className="mt-2 text-xl md:text-4xl font-bold text-center">
                    <Price price={moneyTotal} /> ₮
                  </h1>
                  <p className="text-base md:text-lg text-center">
                    {year + 2003} oнд тэтгэлэгт хамруулсан мөнгөн дүн
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isRegister && <RegisterModal />}
      </div>

      <img
        src="/assets/home/bg.png"
        alt=""
        className="absolute h-full w-full object-cover object-left-bottom lg:object-center z-0 top-0 left-0"
      />
    </div>
  );
}
