const SchoolsInfo = ({ school }) => {
  function truncate(str, n) {
    return str && str?.length > n ? str.slice(0, n - 1) + '...' : str;
  }
  return (
    <>
      <div className="flex justify-center">
        <img
          src={school?.logo ? school?.logo : '/assets/home/none.png'}
          alt={school?.name}
          className="h-12 md:h-20 object-cover"
        />
      </div>
      <div className="text-justify text-ellipsis pt-4">
        <h1 className="text-xl text-center font-bold uppercase my-2 h-14">
          {school?.name}
        </h1>
        {school?.location && (
          <div className="flex items-center border-b border-gray-200 border-dashed mb-4 pb-2 gap-2">
            <p className="">Байршил:</p>
            <p className="font-bold text-yellowc">{school?.location}</p>
          </div>
        )}
        <h1
          className="text-sm text-justify"
          dangerouslySetInnerHTML={{
            __html: truncate(school?.description, 200),
          }}
        />
      </div>
    </>
  );
};
export default SchoolsInfo;
