import axios from 'axios';
import { useApiHandler, fetcherNoToken } from './apiHandler';
import useSWRImmutable from 'swr/immutable';

const userLogin = data =>
  axios.post(`${process.env.BASE_API_URL}/rest/login/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

const register = data =>
  axios.post(`${process.env.BASE_API_URL}/rest/student/v1/rq/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

const courseRegister = data =>
  axios.post(
    `${process.env.BASE_API_URL}/rest/student/v1/course_register/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    }
  );

const getUserInfo = student_number =>
  axios.get(
    `${process.env.BASE_API_URL}/rest/student/v1/code_check/${student_number}/`,
    {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    }
  );
const updateUserInfo = (id, data, accessToken) =>
  axios.put(`${process.env.BASE_API_URL}/rest/student/v1/info/${id}/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const updateUserImage = (id, data, accessToken) =>
  axios.put(
    `${process.env.BASE_API_URL}/rest/student/v1/profile/${id}/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    }
  );

const getStudentStep = accessToken =>
  axios.get(`${process.env.BASE_API_URL}/rest/student/v1/steps/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const getScore = id =>
  axios.get(`${process.env.BASE_API_URL}/rest/student/v1/scores/${id}/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

const getMortgage = accessToken =>
  axios.get(`${process.env.BASE_API_URL}/rest/student/v1/mortgage/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const postMaterialUpload = (data, accessToken) =>
  axios.post(`${process.env.BASE_API_URL}/rest/student/v1/material/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const putMaterialUpload = (id, data, accessToken) =>
  axios.put(
    `${process.env.BASE_API_URL}/rest/student/v1/material/${id}/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    }
  );

const getAllNews = () =>
  axios.get(`${process.env.BASE_API_URL}/rest/provider/v1/news/?limit=10`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      // Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const getCurrentNews = id =>
  axios.get(`${process.env.BASE_API_URL}/rest/provider/v1/news/${id}/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      // Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const getAllUniversity = () =>
  axios.get(`${process.env.BASE_API_URL}/rest/provider/v1/universities/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      // Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const getCurrentUniversity = id =>
  axios.get(
    `${process.env.BASE_API_URL}/rest/provider/v1/universities/${id}/`,
    {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        // Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    }
  );

export const useGetQrPage = ({ subUrl }) => {
  const { data, error, ...rest } = useSWRImmutable(
    [process.env.BASE_API_URL + subUrl],
    fetcherNoToken
  );
  return { data, error, loading: !data && !error, ...rest };
};

const getQrPage = () =>
  axios.get(`${process.env.BASE_API_URL}/rest/provider/v1/qr/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

const getInvoice = accessToken =>
  axios.post(
    `${process.env.BASE_API_URL}/rest/student/v1/invoice/`,
    {},
    {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    }
  );

const putInvitationFile = (id, data, accessToken) =>
  axios.put(
    `${process.env.BASE_API_URL}/rest/student/v1/invitation_payment/${id}/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    }
  );

const postStudentReview = (data, accessToken) =>
  axios.post(`${process.env.BASE_API_URL}/rest/student/v1/review/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const getMentorNews = accessToken =>
  axios.get(`${process.env.BASE_API_URL}/rest/provider/v1/mentor_news/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });
const getMentorCurrentNews = (accessToken, id) =>
  axios.get(`${process.env.BASE_API_URL}/rest/provider/v1/mentor_news/${id}/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });
const getQuizAnswer = () =>
  axios.get(`${process.env.BASE_API_URL}/rest/provider/v1/qa/`);

const postAttachFile = (data, accessToken) =>
  axios.post(`${process.env.BASE_API_URL}/rest/student/v1/attach/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const getSimAttachFile = accessToken =>
  axios.get(`${process.env.BASE_API_URL}/rest/student/v1/sim/`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const postSimAttachFile = (data, accessToken) =>
  axios.post(`${process.env.BASE_API_URL}/rest/student/v1/sim/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const putSimAttachFile = (data, simId, accessToken) =>
  axios.put(`${process.env.BASE_API_URL}/rest/student/v1/sim/${simId}/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });

const postFeedBack = data =>
  axios.post(`${process.env.BASE_API_URL}/rest/student/v1/feedback/`, data, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

const getAttendance = student_code =>
  axios.get(
    `${process.env.BASE_API_URL}/rest/student/v1/attendance/${student_code}/`,
    {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    }
  );

export const useUpdateUserImage = () => useApiHandler(updateUserImage);
export const useUpdateUserInfo = () => useApiHandler(updateUserInfo);
export const userRegister = () => useApiHandler(register);
export const userCourseRegister = () => useApiHandler(courseRegister);
export const useUserLogin = () => useApiHandler(userLogin);
export const useGetStudentStep = () => useApiHandler(getStudentStep);
export const useGetMortgage = () => useApiHandler(getMortgage);
export const usePutMaterialUpload = () => useApiHandler(putMaterialUpload);
export const usePostMaterialUpload = () => useApiHandler(postMaterialUpload);
export const useGetAllNews = () => useApiHandler(getAllNews);
export const useGetCurrentNews = () => useApiHandler(getCurrentNews);
export const useGetAllUniversity = () => useApiHandler(getAllUniversity);
export const useGetCurrentUniversity = () =>
  useApiHandler(getCurrentUniversity);
// export const useGetQrPage = () => useApiHandler(getQrPage);
export const useGetInvoice = () => useApiHandler(getInvoice);
export const useGetScore = () => useApiHandler(getScore);
export const usePutInvitationFile = () => useApiHandler(putInvitationFile);
export const usePostStudentReview = () => useApiHandler(postStudentReview);
export const useGetMentorNews = () => useApiHandler(getMentorNews);
export const useGetMentorCurrentNews = () =>
  useApiHandler(getMentorCurrentNews);
export const useGetQuizAnswer = () => useApiHandler(getQuizAnswer);
export const usePostAttachFile = () => useApiHandler(postAttachFile);
export const useGetSimAttachFile = () => useApiHandler(getSimAttachFile);
export const usePostSimAttachFile = () => useApiHandler(postSimAttachFile);
export const usePutSimAttachFile = () => useApiHandler(putSimAttachFile);
export const usePostFeedBack = () => useApiHandler(postFeedBack);
export const useGetUserInfo = () => useApiHandler(getUserInfo);
export const useGetAttendance = () => useApiHandler(getAttendance);
