import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import ContentWrapper from '@utils/style';
import News from './news';
import Review from './review';
import isEmpty from '@utils/isEmpty';
SwiperCore.use([Navigation, Pagination, Autoplay]);
import AcademyData from '@data/academy.json';
import Schools from './schools';
import SocialData from '@data/social.json';
import Aos from 'aos';

const Service = ({ newsData, reviewData, schoolData }) => {
  function truncate(str, n) {
    return str && str?.length > n ? str.slice(0, n - 1) + '...' : str;
  }

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="bg-white">
      <div className="">
        <div className="py-16">
          <div data-aos="fade-up" className="max-w-7xl mx-auto px-4 md:px-8">
            <h1 className="font-bold uppercase text-2xl text-black md:text-3xl">
              <b className="text-mainc">Эдүлайн</b> тэтгэлэг
            </h1>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 pt-8">
              {AcademyData &&
                AcademyData?.scholarship?.map((ship, i) => (
                  <a key={i} href={ship?.url}>
                    <div
                      className={`${
                        i === 0 && 'bg-gradient-to-r text-white'
                      } group hover:bg-gradient-to-r uppercase from-[#ef156d] to-[#fea459]  border border-mainc/10 py-8 lg:py-14 text-text hover:text-white p-6 rounded-lg shadow-lg cursor-pointer hover:scale-y-105 transition delay-600 duration-300 ease-in-out`}
                    >
                      <div className="flex justify-center">
                        <img src={ship?.img} alt="" className="h-24 sm:h-32" />
                      </div>
                      <h1 className="font-semibold text-center text-base md:text-xl mb-4 lg:mb-6 mt-10 lg:mt-14">
                        {ship?.title}
                      </h1>
                      <div className="flex justify-center">
                        <button
                          className={` ${
                            i === 0
                              ? 'border border-white'
                              : 'bg-mainc text-white group-hover:bg-transparent group-hover:border group-hover:border-white'
                          } rounded-tl-2xl rounded-br-3xl text-white p-3 
                           `}
                        >
                          Дэлгэрэнгүй
                        </button>
                      </div>
                    </div>
                  </a>
                ))}
            </div>
          </div>
        </div>
        {!isEmpty(newsData) && <News data={newsData} />}
        <div
          data-aos="fade-up"
          className="relative max-w-7xl mx-auto space-y-10 px-4 pt-20 md:px-8"
        >
          <h1 className="font-bold uppercase text-2xl text-black md:text-3xl">
            <b className="text-mainc">Эдүлайн</b> солонгос хэлний академи
          </h1>
          <ContentWrapper>
            <Swiper
              slidesPerView={1.2}
              spaceBetween={10}
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                940: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                600: {
                  slidesPerView: 2.4,
                  spaceBetween: 10,
                },
              }}
            >
              {AcademyData &&
                AcademyData?.academy?.map((news, i) => (
                  <SwiperSlide key={i}>
                    <div className="cursor-pointer rounded-lg bg-[#ecedfa] hover:bg-[#ecedfa]">
                      <img
                        src={news?.img}
                        alt={news?.title}
                        className="h-60 rounded w-full object-cover"
                      />
                      <div className="text-justify text-ellipsis p-4">
                        <h1 className="font-semibold text-base">
                          {news?.title}
                        </h1>
                        <h1 className="text-xs">
                          {truncate(news?.description, 200)}
                        </h1>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </ContentWrapper>
        </div>
        {!isEmpty(reviewData) && <Review data={reviewData} />}

        <div className="bg-mainc py-8">
          {/* <div className="h-32 w-32 bg-pinkc/80 z-0 rounded-tr-full absolute" /> */}
          <div className="max-w-7xl mx-auto px-4 md:px-8 gap-10 text-white z-">
            <h1 className="font-bold uppercase text-2xl text-white md:text-3xl md:col-span-2 py-2">
              <b className="text-button">Эдүлайн</b> SNS
            </h1>

            <div className="grid sm:grid-cols-2 lg:grid-cols-4 py-2 gap-8 mt-4">
              {SocialData.map((social, i) => (
                <a
                  href={social?.url}
                  className="relative"
                  key={i}
                  target={'_blank'}
                  data-aos="zoom-in"
                >
                  <div className="relative z-10 bg-white hover:bg-bg text-black rounded p-4 space-y-2 w-4/5 hover:shadow-lg cursor-pointer hover:-translate-y-4 transition delay-600 duration-300 ease-in-out mt-44">
                    <div className="flex items-center gap-2">
                      <div className="bg-white flex justify-center rounded-lg">
                        <img src={social?.img} alt="" className="h-6 w-6" />
                      </div>
                      <div>
                        <div className="text-xs opacity-80">{social?.body}</div>
                        <h1 className="text-mainc font-semibold">
                          {social?.title}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="absolute z-0 top-0 bg-white/20 border-2 border-white/20 flex justify-center items-center w-full rounded-lg p-4">
                    <img
                      src={social?.background}
                      alt=""
                      className="object-cover h-40"
                    />
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
        {!isEmpty(schoolData) && <Schools data={schoolData} />}
      </div>
    </div>
  );
};

export default Service;
